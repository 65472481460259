import type {
    AlertType,
    CapacityBenchmarkType,
    DistanceUnitType,
    FrequencyData,
    GlobalSettingIds,
    ICountryConfig,
    IProductPlanSubscription,
    Integration,
    Notification,
    SettingIds,
    Signature,
    SignedStoredObject,
    SkipReason,
    TAlertTypeUserEmails,
    TemperatureUnitType,
    TranslationKey,
    UserRole,
    WeatherDetailsDictionary,
} from '@nexdynamic/squeegee-common';
import {
    AccountMessagingTemplates,
    AuthorisedUser,
    CapacityMeasurementType,
    CommonApplicationState,
    Countries,
    Currency,
    Device,
    Localisation,
    Product,
    ProductLevel,
    Setting,
    copyObject,
    isVersionGreaterOrEqual,
    notNullUndefinedEmptyOrZero,
    uuid,
    wait,
} from '@nexdynamic/squeegee-common';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { PLATFORM, computedFrom } from 'aurelia-framework';
import type { NavigationOptions } from 'aurelia-history';
import type { RouteConfig, Router, RouterConfiguration } from 'aurelia-router';
import type { BindingSignaler } from 'aurelia-templating-resources';
import i18next from 'i18next';
import moment from 'moment';
import { ReleaseNotesDialog } from './About/ReleaseNotes';
import { ApplicationEnvironment } from './ApplicationEnvironment';
import { ApplicationStateFlags } from './ApplicationStateFlags';
import { AttachmentService } from './Attachments/AttachmentService';
import { SelectAttachmentsDialog } from './Attachments/Dialogs/SelectAttachmentsDialog';
import { DateTimePicker } from './Components/DateTimePicker/DateTimePicker';
import type { CustomerListSortType } from './Customers/Components/CustomerListSortType';
import { Data } from './Data/Data';
import type { DataChangeNotificationType } from './Data/DataChangeNotificationType';
import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';
import { DaynoteActions } from './Daynotes/Daynote.actions';
import { AddressLookupDialog } from './Dialogs/AddressLookupDialog';
import { DialogAnimation } from './Dialogs/DialogAnimation';
import { prompt } from './Dialogs/ReactDialogProvider';
import { SelectMultiple } from './Dialogs/SelectMultiple';
import { SignatureDialog } from './Dialogs/Signatures/SignatureDialog';
import { HasAdvertsEvent } from './Events/HasAdvertsEvent';
import { LoaderEvent } from './Events/LoaderEvent';
import { PermissionsUpdateEvent } from './Events/PermissionsUpdateEvent';
import { ReauthenticateEvent } from './Events/ReauthenticateEvent';
import { SubscriptionUpdatedEvent } from './Events/SubscriptionUpdatedEvent';
import { Features } from './Features';
import { GlobalFlags } from './GlobalFlags';
import type { IRoute } from './IRoute';
import type { IViewOptions } from './IViewOptions';
import { IntegrationClientRoute } from './IntegrationClientRoute';
import { LanguagesAndCountries } from './LanguagesAndCountries';
import { DeveloperLaunchOptionsDialog } from './Launch/DeveloperLaunchOptionsDialog';
import { PositionUpdatedEvent } from './Location/PositionUpdatedEvent';
import { Logger } from './Logger';
import { ActionableEvent } from './Notifications/ActionableEvent';
import { NotifyUserMessage } from './Notifications/NotifyUserMessage';
import { QuoteService } from './Quotes/QuoteService';
import initThemeProvider from './ReactUI/SqueegeeThemeProvider';
import type { CannedResponse } from './ReactUI/canned-responses/CannedResponse';
import { RouteUtil } from './Routes';
import { Api } from './Server/Api';
import type { IApplicationStateResponse } from './Server/IApplicationStateResponse';
import type { IPingResponse } from './Server/IPingResponse';
import { RethinkDbAuthClient } from './Server/RethinkDbAuthClient';
import { SqueegeeClientSocketApi } from './Server/SqueegeeClientSocketApi';
import { SubscriptionApi } from './Server/SubscriptionApi';
import { MessageTemplates } from './Settings/MessageTemplates';
import { Shortcuts } from './Shortcuts';
import { Stopwatch } from './Stopwatch';
import { SupportDialog } from './Support/SupportDialog';
import { UserService } from './Users/UserService';
import { Utilities } from './Utilities';
import { isDevMode } from './isDevMode';

const data = require('../package.json');

export const PUSH_ALERT_TYPES: Array<AlertType> = [
    'appointment',
    'customer',
    'email',
    'message',
    'payment',
    'quote',
    'sms',
    'squeegee',
    'system',
];

export class ApplicationState {
    private static isInitialising = false;
    public static hasInitialised = false;
    public static initialisation: Promise<void>;

    public static async init() {
        if (ApplicationState.hasInitialised) return;

        if (ApplicationState.isInitialising) throw new Error('Application state is currently initialising.');

        ApplicationState.isInitialising = true;

        ApplicationState.initialisation = new Promise<void>(async (resolve, reject) => {
            try {
                const stopwatch = new Stopwatch('ApplicationState Init Inner');

                const local = Data.get<CommonApplicationState>(CommonApplicationState.APPLICATION_STATE_ID);

                if (local) ApplicationState.updateApplicationStateInstance(local);

                const updateWrapper = new Promise<void>(async (updateResolve, updateReject) => {
                    try {
                        const remote = await Api.getApplicationState();

                        if (!remote.failed && !remote.remoteApplicationState && !local) {
                            return updateReject(
                                'Unable to get a valid application state locally or remotely, please check your connection.'
                            );
                        }

                        await ApplicationState.checkAndUpdate(remote, local);

                        return updateResolve();
                    } catch (error) {
                        if (local) Data.refreshAccountOverride(local.account.email);
                        else {
                            await RethinkDbAuthClient.updateDefaultAccount();
                            await ApplicationState.setAccountOverride(undefined);
                            Data.reset();
                        }
                        Logger.error(error);
                        return updateReject(error);
                    }
                });

                if (!local) {
                    await updateWrapper;
                    if (!ApplicationState.instance)
                        throw 'Unable to get an application state locally or from the server, please check your connection.';
                }

                stopwatch.lap('Confirm application state');

                await Shortcuts.init();
                stopwatch.lap('Initialise keyboard shortcuts');

                await ApplicationState.refreshLocale();
                stopwatch.lap('Update locale');

                stopwatch.lap('Configure location updates');

                stopwatch.stop();

                ApplicationState.hasInitialised = true;
                ApplicationState.isInitialising = false;

                Data.cleanUp();
                ApplicationState.initCrossBrowserEvents();
                return resolve();
            } catch (error) {
                ApplicationState.isInitialising = false;
                return reject(error);
            }
        });

        await ApplicationState.initialisation;
        initThemeProvider();
    }

    public static get cannedResponses() {
        return ApplicationState.getSetting<Array<CannedResponse>, undefined>('global.canned-responses', []);
    }

    public static get skills() {
        return ApplicationState.getSetting<Array<string>, undefined>('global.skills', []);
    }

    public static get skipReasons() {
        let skipReasons = ApplicationState.getSetting<Array<SkipReason>, undefined>('global.skip-reasons-and-charges');
        if (!skipReasons) {
            skipReasons = (ApplicationState.account.predefinedSkipReasons || '')
                .split('\n')
                .map(x => x.trim())
                .filter(x => !!x)
                .map(x => ({ name: x, skipCharge: undefined }));

            ApplicationState.setSetting('global.skip-reasons-and-charges', skipReasons);
            ApplicationState.account.predefinedSkipReasons = <any>null;
            ApplicationState.save();
        }

        return skipReasons;
    }

    public static set skipReasons(value: Array<SkipReason>) {
        ApplicationState.setSetting('global.skip-reasons-and-charges', value);
    }

    static get creditorAccountId() {
        return ApplicationState.dataEmail + '-creditors';
    }
    static get debtorAccountId() {
        return ApplicationState.dataEmail + '-debtors';
    }
    static get taxAccountId() {
        return ApplicationState.dataEmail + '-tax';
    }

    public static get mySignature() {
        return ApplicationState.getSetting<Signature | undefined, string>(
            'global.staff-signature',
            undefined,
            RethinkDbAuthClient.session?.email
        );
    }

    public static get canShowAccountManagement() {
        return isDevMode() || !GlobalFlags.isAppleMobileApp || Api.appleReviewed;
    }

    private static _hasAdverts: boolean;
    public static get hasAdverts() {
        return ApplicationState._hasAdverts;
    }

    public static set hasAdverts(value: boolean) {
        ApplicationState._hasAdverts = value;
        new HasAdvertsEvent(value);
    }

    public static setZoom(zoomLevel?: number) {
        if (!zoomLevel) {
            const savedZoom = localStorage.getItem('accessibility.zoom-level');
            if (!savedZoom) return;

            zoomLevel = Number(savedZoom) || 1;
        }
        const viewport = document.querySelector('meta[name=viewport]');
        if (!viewport) return;

        const viewportSetting = `initial-scale=${zoomLevel}, maximum-scale=1.3, user-scalable=no, width=device-width, viewport-fit=cover`;
        Logger.info('Setting viewport', { from: viewport.getAttribute('content'), to: viewportSetting });
        viewport.setAttribute('content', viewportSetting);
    }

    static _mobilePrefixes?: Array<string> | null;
    public static get mobilePrefixes(): Array<string> | null {
        if (ApplicationState._mobilePrefixes === undefined) {
            const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes
                ?.split(',')
                .map(x => x.trim())
                .filter(notNullUndefinedEmptyOrZero);
            ApplicationState._mobilePrefixes = mobilePrefixes?.length ? mobilePrefixes : null;
        }
        return ApplicationState._mobilePrefixes;
    }

    static async getPortalInviteLink(customerRef?: string) {
        if (!customerRef) return '';

        let portalUrl = ApplicationState.getSetting<string | undefined>('global.portal-url');

        if (!portalUrl) {
            const response = await Api.get<IDirectoryEntryDetails>(Api.apiEndpoint, `/api/directory/secure/${ApplicationState.dataEmail}`);
            if (response && response.data && response.data.uniqueUrl) {
                portalUrl = Utilities.generatePortalUrl(response.data.uniqueUrl);
                ApplicationState.setSetting('global.portal-url', portalUrl);
            }
        }

        if (!portalUrl) {
            Logger.error('Unable to generate portal URL', { customerRef });
            return '';
        }

        const link = `${portalUrl}/my-account?data-customer-ref=${customerRef}`;

        return await Utilities.getShortUrl(link);
    }

    public static get isVerifiedForMessaging() {
        // WTF: Allow non owners to send messages as they will be blocked server side if the account is not verified.
        return RethinkDbAuthClient.session?.email !== ApplicationState.dataEmail || !RethinkDbAuthClient.session?.unverified;
    }

    static get defaultJobFrequency(): FrequencyData | undefined {
        if (!ApplicationState.account.defaultJobFrequency) return;
        const defaultJobFrequency = copyObject(ApplicationState.account.defaultJobFrequency);
        defaultJobFrequency.firstScheduledDate = moment().format('YYYY-MM-DD');
        Utilities.fixFrequencyData(defaultJobFrequency);
        return defaultJobFrequency;
    }
    static set defaultJobFrequency(value: FrequencyData | undefined) {
        ApplicationState.account.defaultJobFrequency = value;
    }
    public static async setBusinessLogo() {
        const attachmentDialog = new SelectAttachmentsDialog(undefined, { selectOne: true, isPublic: true });
        const attachment = (await attachmentDialog.show(DialogAnimation.SLIDE))?.[0];
        if (attachmentDialog.cancelled) return;

        if (!attachment.mimeType?.startsWith('image')) return new NotifyUserMessage('select.image-attachment');

        if (!attachment.isPublic) return new NotifyUserMessage('image.needs-public-access-enabled');

        const url = AttachmentService.getPublicUrl(attachment);

        ApplicationState.account.businessLogo = url;

        await ApplicationState.save();
    }

    public static initialiseDeviceInfo() {
        try {
            if (RethinkDbAuthClient.session?.freeDevice) return;

            let deviceDetails: ConstructorParameters<typeof Device>[0] | undefined;
            if (GlobalFlags.isHttp) {
                let webUuid = window.localStorage.getItem('squeegee-device-uuid');
                if (!webUuid) {
                    webUuid = uuid();
                    window.localStorage.setItem('squeegee-device-uuid', webUuid);
                }

                deviceDetails = {
                    cordova: '',
                    model: navigator?.userAgent,
                    platform: 'web',
                    uuid: webUuid,
                    version: '',
                    manufacturer: '',
                    isVirtual: '',
                    serial: '',
                    appVersion: '',
                };
            } else {
                deviceDetails = (window as any)?.device;
                if (deviceDetails) deviceDetails.appVersion = ApplicationState.version;
            }

            if (!deviceDetails) return;

            const updatedDevice = new Device(deviceDetails);

            const device = Data.firstOrDefault<Device>('devices', {
                uuid: deviceDetails.uuid.toString(),
                appVersion: updatedDevice.appVersion,
            });

            if (device) {
                updatedDevice._id = device._id;
                updatedDevice.createdDate = device.createdDate;
                updatedDevice.opens = (updatedDevice.opens || 0) + 1;
                updatedDevice.reinitialisedDatabase = device.reinitialisedDatabase;
            }

            ApplicationState._deviceId = updatedDevice._id;
            ApplicationState._deviceDescription = `${updatedDevice.manufacturer} ${updatedDevice.model}`;

            if (SqueegeeLocalStorage.isFreshInstall) {
                SqueegeeLocalStorage.setItem('db_initialised_on_device', updatedDevice.uuid);
                SqueegeeLocalStorage.setItem('db_initialised_on_version', updatedDevice.appVersion);
                SqueegeeLocalStorage.setItem('db_initialised_on_date', new Date().toISOString());
                updatedDevice.reinitialisedDatabase = true;
            } else {
                const thisDeviceInstalledFresh = SqueegeeLocalStorage.getItem('db_initialised_on_device') === updatedDevice.uuid;
                const thisDeviceInstalledVersion = SqueegeeLocalStorage.getItem('db_initialised_on_version') === updatedDevice.appVersion;

                if (thisDeviceInstalledFresh && thisDeviceInstalledVersion) {
                    updatedDevice.reinitialisedDatabase = true;
                } else {
                    updatedDevice.reinitialisedDatabase = false;
                }
            }

            Data.put(updatedDevice);
        } catch (error) {
            Logger.error('Unable to initialise device info', error);
        }
    }

    private static _deviceId = '';
    public static get deviceId() {
        return ApplicationState._deviceId;
    }

    private static _deviceDescription = '';
    public static get deviceDescription() {
        return ApplicationState._deviceDescription;
    }

    public static getPushAlertsForUser(email: string): Array<AlertType> {
        const pushAlerts = [] as Array<AlertType>;
        const alertTypeUserEmails = ApplicationState.getSetting<TAlertTypeUserEmails>('global.alert-type-user-emails', {});
        for (const alertType of PUSH_ALERT_TYPES) {
            if (alertTypeUserEmails[alertType]?.some(x => x === email) || alertType === 'system') pushAlerts.push(alertType);
        }
        return pushAlerts;
    }
    public static async selectPushAlerts(pushAlerts?: Array<AlertType>): Promise<Array<AlertType> | undefined> {
        const options = PUSH_ALERT_TYPES.map(x => ({ value: x, text: `alert-type.${x}` }));
        const selected = options.filter(x => pushAlerts?.some(y => y === x.value));
        const select = new SelectMultiple('Push Notification Alerts Enabled' as TranslationKey, options, 'text', 'value', selected);
        const nowSelected = await select.show();
        if (select.cancelled) return;

        return nowSelected.map(x => x.value);
    }

    public static updatePushAlerts(email: string, pushAlerts: Array<AlertType>) {
        const setting = ApplicationState.getSetting<TAlertTypeUserEmails>('global.alert-type-user-emails', {});
        if (pushAlerts.length) {
            for (const alertType of pushAlerts) {
                if (!setting[alertType]) setting[alertType] = [];
                if (!setting[alertType].some(x => x === email)) {
                    setting[alertType].push(email);
                }
            }
        }
        for (const alertType in setting) {
            if (!setting[alertType]) setting[alertType] = [];
            if (!pushAlerts.length || !pushAlerts.some(x => x === alertType)) {
                setting[alertType] = setting[alertType].filter(x => x !== email);
            }
        }
        ApplicationState.setSetting('global.alert-type-user-emails', setting);
        Logger.info('New alerts settings.', setting);
    }

    public static getSettingId(id: SettingIds) {
        const uuid = ApplicationState.account.uuid;
        if (uuid) return `${uuid}-${id}`;

        Logger.error('Unable to load setting, UUID is not available', { id });
        throw 'Setting ID cannot be generated cannot load, session user ID is not available.';
    }
    public static getSetting<TSettingType, TDynamicIdType extends string | undefined = undefined>(
        id: SettingIds,
        defaultValue: TSettingType,
        dynamicId?: TDynamicIdType
    ): TSettingType;

    public static getSetting<TSettingType, TDynamicIdType extends string | undefined = undefined>(
        id: SettingIds,
        defaultValue?: undefined,
        dynamicId?: TDynamicIdType
    ): TSettingType | undefined;

    public static getSetting<TSettingType, TDynamicIdType extends string | undefined = undefined>(
        id: SettingIds,
        defaultValue?: TSettingType,
        dynamicId?: TDynamicIdType
    ): TSettingType | undefined {
        const uuid = ApplicationState?.account?.uuid;
        if (!uuid) {
            Logger.error('Unable to load setting, UUID is not available', { id });
            throw 'Settings cannot load, session user ID is not available.';
        }
        const settingId = Setting.getId(uuid, id, dynamicId);

        const setting = Data.get<Setting<TSettingType>>(settingId);
        if (setting?.value !== undefined) {
            return Utilities.copyObject(setting.value);
        } else if (defaultValue !== undefined) {
            return defaultValue;
        } else {
            return undefined;
        }
    }

    public static async setSetting<TSettingType, TDynamicIdType extends string | undefined = undefined>(
        id: SettingIds,
        value: TSettingType,
        dynamicId?: TDynamicIdType
    ): Promise<void> {
        const uuid = ApplicationState.account.uuid;
        if (!uuid) {
            Logger.error('Unable to load setting, UUID is not available', { id });
            throw 'Settings cannot load, session user ID is not available.';
        }
        const settingId = Setting.getId(uuid, id, dynamicId);
        const existingSetting = Data.get<Setting<TSettingType>>(settingId);
        if (existingSetting) {
            if (JSON.stringify(existingSetting.value) === JSON.stringify(value)) return;

            existingSetting.value = copyObject(value);
            await Data.put(existingSetting);
            if (ApplicationState.stateFlags.devMode) Logger.info(id, value);
            return;
        }

        const setting = new Setting<TSettingType, TDynamicIdType>(uuid, id, copyObject(value), dynamicId);
        await Data.put(setting);
        if (ApplicationState.stateFlags.devMode) Logger.info(id, value);
    }

    public static async clearSetting<TSettingType>(id: SettingIds, dynamicId?: string): Promise<void> {
        const uuid = ApplicationState.account.uuid;
        if (!uuid) {
            Logger.error('Unable to load setting, UUID is not available', { id });
            throw 'Setting cannot be cleared, session user ID is not available.';
        }
        const settingId = Setting.getId(uuid, id, dynamicId);
        const existingSetting = Data.get<Setting<TSettingType>>(settingId);
        if (existingSetting) await Data.delete(existingSetting);
    }

    public static async initIntegrationRoutes() {
        if (ApplicationState.integrationRoutes) return;

        while (!RethinkDbAuthClient.session || !SqueegeeClientSocketApi.isSocketConnected || !ApplicationState.router) await wait(50);

        const integrationsResult = await Api.get<Array<Integration>>(null, '/api/integrations');

        const integrations = integrationsResult?.data?.length ? integrationsResult.data : [];

        const integrationRoutes = integrations.map(integration => new IntegrationClientRoute(integration));

        let count = 0;
        while (!ApplicationState.routeMapper) {
            await wait(500);
            count++;
            if (count > 10) return;
        }
        ApplicationState.router.configure(ApplicationState.routeMapper(integrationRoutes));

        ApplicationState.integrationRoutes = integrationRoutes;

        ApplicationState.refreshNavigation();
    }
    public static routeMapper?: (route: RouteConfig | RouteConfig[]) => RouterConfiguration;
    public static integrationRoutes: Array<IntegrationClientRoute>;

    public static getSupport(subject: string, subjectIsEditable: boolean, message = '', customHeading: TranslationKey = 'empty.string') {
        return new SupportDialog(subjectIsEditable, subject, message, customHeading).show(DialogAnimation.SLIDE_UP);
    }

    public static canInstall: boolean;

    private static _spotlessWaterMarkersDisabled: boolean;
    static _alreadyReady: any;
    @computedFrom('_spotlessWaterMarkersDisabled')
    public static get spotlessWaterMarkersDisabled() {
        if (ApplicationState._spotlessWaterMarkersDisabled === undefined) {
            ApplicationState._spotlessWaterMarkersDisabled = SqueegeeLocalStorage.getItem('spotlessWaterMarkersDisabled') === 'true';
        }
        return ApplicationState._spotlessWaterMarkersDisabled;
    }

    public static set spotlessWaterMarkersDisabled(enabled: boolean) {
        ApplicationState._spotlessWaterMarkersDisabled = enabled;
        SqueegeeLocalStorage.setItem('spotlessWaterMarkersDisabled', enabled.toString());
    }

    private static _showAllJobHistoryContainer: { [jobId: string]: boolean };
    private static get showAllJobHistoryContainer() {
        if (ApplicationState._showAllJobHistoryContainer === undefined) {
            const json = SqueegeeLocalStorage.getItem('showAllJobHistory');
            try {
                ApplicationState._showAllJobHistoryContainer = json ? (JSON.parse(json) as { [jobId: string]: boolean }) : {};
            } catch {
                ApplicationState._showAllJobHistoryContainer = {};
            }
        }
        return ApplicationState._showAllJobHistoryContainer;
    }
    public static showAllJobHistory(jobId: string) {
        return !!ApplicationState.showAllJobHistoryContainer[jobId];
    }

    public static toggleShowAllJobHistory(jobId: string) {
        const showAllJobHistory = ApplicationState.showAllJobHistoryContainer;
        showAllJobHistory[jobId] = !showAllJobHistory[jobId];
        SqueegeeLocalStorage.setItem('showAllJobHistory', JSON.stringify(showAllJobHistory));
        return showAllJobHistory[jobId];
    }

    public static isFranchise(): boolean {
        return !!Data.count('franchisee');
    }

    public static async signObject(signable: SignedStoredObject, reason: string, selfSigned: boolean): Promise<boolean> {
        let signature: Signature | undefined;
        try {
            new LoaderEvent(false);
            const fullname = selfSigned ? (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || '' : '';
            const signatureDialog = new SignatureDialog('sign.off-job-appstate', 'global.sign', false, undefined, true, fullname, reason);
            signature = await signatureDialog.show();
            if (signatureDialog.cancelled || !signature) return false;

            await Data.put(signature);
            signable.signatureId = signature._id;
            await Data.put(signable);
            return true;
        } catch (error) {
            Logger.error('There was an error signing the object', { signable, signature, error });
            return false;
        }
    }

    public static viewAsJson = async (data: any) => {
        if (!window.sq.dbg) window.sq.dbg = {};

        window.sq.dbg.temp = data;
        (window as any)['sqtemp'] = data;

        /*  */ console.info(`sqtemp`, data); /*  */

        new NotifyUserMessage('user.message-sqtemp-data-log');
    };

    public static onlineRequiredCheck(_feature: TranslationKey): Promise<boolean> {
        return new Promise<boolean>(async resolve => {
            if (Api.isConnected) return resolve(true);

            await prompt('general.offline', 'feature.unavailable-while-you-are-offline', {
                cancelLabel: 'general.ok',
            }).show();
            return resolve(false);
        });
    }

    public static signaler?: BindingSignaler;

    private static _positionInitialised?: Promise<void>;
    @computedFrom('_positionInitialised')
    public static get positionInitialised() {
        if (!ApplicationState._positionInitialised) {
            ApplicationState.initPosition();
        }
        return ApplicationState._positionInitialised;
    }

    public static positionNotAvailable: boolean;

    public static get hasCachedSubscription(): boolean {
        return !!SqueegeeLocalStorage.getItem('squeegee_sub');
    }

    public static get subscription(): IProductPlanSubscription {
        const data = SqueegeeLocalStorage.getItem('squeegee_sub') || null;

        if (!data) {
            const subNotFound = { product: Product.notSubscribed };
            return subNotFound;
        }

        try {
            const sub = JSON.parse(data) as IProductPlanSubscription;
            if (sub.status === 'unpaid' && sub.product.productLevel !== ProductLevel.NotSubscribed)
                sub.product.productLevel = ProductLevel.NotSubscribed;

            return sub;
        } catch (error) {
            Logger.error('Invalid subscription data', { data, error });
            return { product: Product.notSubscribed };
        }
    }

    public static set subscription(subscription: IProductPlanSubscription) {
        try {
            if (subscription) SqueegeeLocalStorage.setItem('squeegee_sub', JSON.stringify(subscription));
            else SqueegeeLocalStorage.removeItem('squeegee_sub');

            new SubscriptionUpdatedEvent();
        } catch (error) {
            Logger.error('Invalid subscription data', { error });
        }
    }

    public static hasMinimumSubscription(type?: ProductLevel): boolean {
        if (type === undefined) return true;
        return ApplicationState.subscription.product.productLevel >= type || false;
    }

    public static isInAnyRole(roleOrRoles: UserRole | Array<UserRole>): boolean {
        if (typeof roleOrRoles === 'string') roleOrRoles = [roleOrRoles];
        if (ApplicationState.userAuthorisation && ApplicationState.userAuthorisation.roles) {
            for (const role of roleOrRoles) {
                if (ApplicationState.userAuthorisation.roles.find(r => r === role)) return true;
            }
        }
        return false;
    }

    public static isOnlyInRole(roleOrRoles: UserRole | Array<UserRole>): boolean {
        if (typeof roleOrRoles === 'string') roleOrRoles = [roleOrRoles];
        // check if every role user is in is one of the roles passed in
        if (ApplicationState.userAuthorisation && ApplicationState.userAuthorisation.roles)
            if (ApplicationState.userAuthorisation.roles.every(r => (roleOrRoles as UserRole[]).some(e => e === r))) return true;
        return false;
    }

    public static router: Router;
    public static navigateTo(route: string, params?: any, options?: NavigationOptions): boolean {
        return this.router.navigateToRoute(route, params, options);
    }
    public static navigateToRouteFragment(routeFragment: string, options?: NavigationOptions): boolean {
        return this.router.navigate(routeFragment, options);
    }

    public static canNavigateTo(route: IRoute): any {
        if (!ApplicationState.hasMinimumSubscription(route.settings.minimumSubscription)) return false;

        if (route.settings.enabled && !route.settings.enabled()) return false;

        if (route.settings.roles && !ApplicationState.isInAnyRole(route.settings.roles)) return false;

        return true;
    }

    public static get defaultRoute() {
        if (
            !ApplicationState.subscription ||
            ApplicationState.subscription.product.productLevel === -1 ||
            !ApplicationState.userAuthorisation ||
            !ApplicationState.userAuthorisation.roles ||
            !ApplicationState.userAuthorisation.roles.length
        ) {
            return 'about';
        }

        if (ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) return 'customers';

        if (ApplicationState.isInAnyRole('Planner')) return 'schedule';

        return 'mywork';
    }

    public static refreshNavigation: () => void = () => undefined;

    static refreshOwnerAuthorisation(): any {
        delete ApplicationState._userAuthorisation;
    }

    private static _checkingAndUpdating = false;
    public static async checkAndUpdate(remote?: IApplicationStateResponse, local?: CommonApplicationState) {
        if (ApplicationState._checkingAndUpdating) return;

        ApplicationState._checkingAndUpdating = true;
        try {
            local = local || Data.get<CommonApplicationState>(CommonApplicationState.APPLICATION_STATE_ID);
            remote = remote || (await Api.getApplicationState());

            if (local && remote.remoteApplicationState.status === 'same') {
                if (!ApplicationState._instance) ApplicationState.updateApplicationStateInstance(local);
                return;
            }

            if (remote.failed && local) {
                Logger.info('Failed to get remote application state, using local.', { local, remote });
                if (!ApplicationState._instance) ApplicationState.updateApplicationStateInstance(local);
                return;
            }

            if (remote.remoteApplicationState.applicationState && remote.remoteApplicationState.status === 'remote-newer') {
                Logger.info('Newer remote application state applied.', { local, remote });
                await Data.put(remote.remoteApplicationState.applicationState, false);
                return;
            }

            if (local && remote.remoteApplicationState.status === 'local-newer') {
                Logger.info('Local application state is newer, sending it back.', { local, remote });
                Api.putApplicationState(local);
                if (!ApplicationState._instance) ApplicationState.updateApplicationStateInstance(local);
                return;
            }

            throw 'No local or remote application state found, something is terribly wrong!';
        } finally {
            ApplicationState._checkingAndUpdating = false;
        }
    }

    public static updateApplicationStateInstance(applicationState: CommonApplicationState) {
        ApplicationState._instance = applicationState;
        delete ApplicationState._messagingTemplates;
    }

    private static _accountBalance?: { balance: number; currencySymbol: string };
    @computedFrom('_accountBalance')
    public static get accountBalance() {
        return ApplicationState._accountBalance;
    }

    public static async updateAccountBalance() {
        ApplicationState._accountBalance = await SubscriptionApi.getCustomerBalance();
        return ApplicationState._accountBalance;
    }

    public static async manageDayNotes() {
        const dayNoteId: GlobalSettingIds = 'global.day-note';
        for (;;) {
            const daynotes = Data.all<Setting<string>>('settings', s => s._id.indexOf(dayNoteId) > -1);
            const highlightMap = daynotes
                .map(d => d._id.slice(-10))
                .reduce((x, y) => {
                    x[y] = { allowPick: true };
                    return x;
                }, {} as Record<string, { allowPick: boolean }>);

            const datePicker = new DateTimePicker(false, undefined, 'daynotes.manage-notes', true, highlightMap, 'note_alt');
            datePicker.okOnSelect = true;
            datePicker.cancelLabel = 'general.finished';
            datePicker.init();
            await datePicker.open();
            if (datePicker.canceled || !datePicker.selectedDate) break;

            const content = ApplicationState.getSetting('global.day-note', undefined, datePicker.selectedDate);
            await DaynoteActions.update(datePicker.selectedDate, content);
            await wait(50);
        }
    }

    private static _serverVersions: Promise<{ version: string; minimumSupportedVersion: string } | null>;
    public static async serverVersions() {
        if (ApplicationState._serverVersions) return ApplicationState._serverVersions;

        try {
            ApplicationState._serverVersions = new Promise<{ version: string; minimumSupportedVersion: string } | null>(async resolve => {
                try {
                    const ping = await Api.ping();
                    if (!ping?.versionInfo) return resolve(null);

                    const serverVersion = {
                        version: ping.versionInfo.version,
                        minimumSupportedVersion: ping.versionInfo.minimumSupportedVersion,
                    };

                    return resolve(serverVersion);
                } catch (error) {
                    Logger.error('Unable to get server versions', error);
                    return resolve(null);
                }
            });
            return ApplicationState._serverVersions;
        } catch (error) {
            Logger.error('Unable to get server versions', error);
            return null;
        }
    }

    public static get version() {
        return `${data.version}`;
    }

    public static get lastReleaseNotesShown() {
        return SqueegeeLocalStorage.getItem('lastReleaseNotesShown') || '';
    }

    public static set lastReleaseNotesShown(version: string) {
        SqueegeeLocalStorage.setItem('lastReleaseNotesShown', version);
    }

    public static async showReleaseNotesIfNew() {
        const version = ApplicationState.version;
        if (version !== ApplicationState.lastReleaseNotesShown && ApplicationState.subscription) {
            await new ReleaseNotesDialog().show(DialogAnimation.SLIDE);
            ApplicationState.lastReleaseNotesShown = version;
        }
    }

    public static stateFlags: ApplicationStateFlags;

    public static async setRootLaunch() {
        await ApplicationEnvironment.aurelia.setRoot(PLATFORM.moduleName('Launch/Launch'));
    }

    public static async setRootStartup() {
        await await ApplicationEnvironment.aurelia.setRoot(PLATFORM.moduleName('Startup/Startup'));
    }

    public static async setRootSqueegee() {
        await await ApplicationEnvironment.aurelia.setRoot(PLATFORM.moduleName('Squeegee'));
    }

    private static _viewOptions: IViewOptions;
    public static get viewOptions() {
        if (!ApplicationState._viewOptions) ApplicationState.reloadViewOptions();
        return ApplicationState._viewOptions;
    }
    public static reloadViewOptions() {
        this._viewOptions = {
            showTemperatures: SqueegeeLocalStorage.getItem('showTemperatures') === 'true' ? true : false,
            minimiseChartData: SqueegeeLocalStorage.getItem('minimiseChartData') === 'true' ? true : false,
            hideDoneAndSkippedItems: SqueegeeLocalStorage.getItem('hideDoneAndSkippedItems') === 'true' ? true : false,
            optimiseItemOrder: SqueegeeLocalStorage.getItem('optimiseItemOrder') === 'true' ? true : false,
            showActiveCustomers:
                SqueegeeLocalStorage.getItem('showActiveCustomers') === null
                    ? true
                    : SqueegeeLocalStorage.getItem('showActiveCustomers') === 'true'
                    ? true
                    : false,
            showInactiveCustomers:
                SqueegeeLocalStorage.getItem('showInactiveCustomers') === null
                    ? true
                    : SqueegeeLocalStorage.getItem('showInactiveCustomers') === 'true'
                    ? true
                    : false,
            showProspectiveCustomers:
                SqueegeeLocalStorage.getItem('showProspectiveCustomers') === null
                    ? true
                    : SqueegeeLocalStorage.getItem('showProspectiveCustomers') === 'true'
                    ? true
                    : false,
            onlyOverdueUnpaidInvoices: SqueegeeLocalStorage.getItem('onlyOverdueUnpaidInvoices') === 'true' ? true : false,
            onlyShowOffSchedule: SqueegeeLocalStorage.getItem('onlyShowOffSchedule') === 'true' ? true : false,
            offScheduleToleranceLate: Number(SqueegeeLocalStorage.getItem('offScheduleToleranceLate')) || 100,
            offScheduleToleranceEarly: Number(SqueegeeLocalStorage.getItem('offScheduleToleranceEarly')) || 25,
            groupSchedule: SqueegeeLocalStorage.getItem('groupSchedule') === 'true' ? true : false,
            hidePricesForPrivacy: SqueegeeLocalStorage.getItem('hidePricesForPrivacy') === 'true' ? true : false,
            customerListSortOrder: <CustomerListSortType>SqueegeeLocalStorage.getItem('customerListSortOrder'),
            customerListSortOrderOwing: <CustomerListSortType>SqueegeeLocalStorage.getItem('customerListSortOrderOwing'),
            customerListSortOrderCanvassing: <CustomerListSortType>SqueegeeLocalStorage.getItem('customerListSortOrderCanvassing'),
        };
    }

    public static saveViewOptions() {
        SqueegeeLocalStorage.setItem('showTemperatures', this.viewOptions.showTemperatures.toString());
        SqueegeeLocalStorage.setItem('minimiseChartData', this.viewOptions.minimiseChartData.toString());
        SqueegeeLocalStorage.setItem('hideDoneAndSkippedItems', this.viewOptions.hideDoneAndSkippedItems.toString());
        SqueegeeLocalStorage.setItem('optimiseItemOrder', this.viewOptions.optimiseItemOrder.toString());
        if (this.viewOptions.customerListSortOrder !== null)
            SqueegeeLocalStorage.setItem('customerListSortOrder', this.viewOptions.customerListSortOrder);
        else SqueegeeLocalStorage.removeItem('customerListSortOrder');
        if (this.viewOptions.customerListSortOrderOwing !== null)
            SqueegeeLocalStorage.setItem('customerListSortOrderOwing', this.viewOptions.customerListSortOrderOwing);
        else SqueegeeLocalStorage.removeItem('customerListSortOrderOwing');
        SqueegeeLocalStorage.setItem('showActiveCustomers', this.viewOptions.showActiveCustomers.toString());
        SqueegeeLocalStorage.setItem('showInactiveCustomers', this.viewOptions.showInactiveCustomers.toString());
        SqueegeeLocalStorage.setItem('showProspectiveCustomers', this.viewOptions.showProspectiveCustomers.toString());
        SqueegeeLocalStorage.setItem('onlyShowOffSchedule', this.viewOptions.onlyShowOffSchedule.toString());
        SqueegeeLocalStorage.setItem('offScheduleToleranceLate', this.viewOptions.offScheduleToleranceLate.toString());
        SqueegeeLocalStorage.setItem('offScheduleToleranceEarly', this.viewOptions.offScheduleToleranceEarly.toString());
        SqueegeeLocalStorage.setItem('onlyOverdueUnpaidInvoices', this.viewOptions.onlyOverdueUnpaidInvoices.toString());
        SqueegeeLocalStorage.setItem('groupSchedule', this.viewOptions.groupSchedule.toString());
        SqueegeeLocalStorage.setItem('hidePricesForPrivacy', this.viewOptions.hidePricesForPrivacy.toString());
    }
    public static viewOptionsData = {
        set showTemperatures(value: boolean) {
            SqueegeeLocalStorage.setItem('showTemperatures', value.toString());
            this.reloadViewOptions();
        },

        set minimiseChartData(value: boolean) {
            SqueegeeLocalStorage.setItem('minimiseChartData', value.toString());
            this.reloadViewOptions();
        },

        set hideDoneAndSkippedItems(value: boolean) {
            SqueegeeLocalStorage.setItem('hideDoneAndSkippedItems', value.toString());
            this.reloadViewOptions();
        },

        set optimiseItemOrder(value: boolean) {
            SqueegeeLocalStorage.setItem('optimiseItemOrder', value.toString());
        },

        set customerListSortOrder(value: CustomerListSortType) {
            if (value !== null) SqueegeeLocalStorage.setItem('customerListSortOrder', value);
            else SqueegeeLocalStorage.removeItem('customerListSortOrder');
            this.reloadViewOptions();
        },
        set customerListSortOrderOwing(value: CustomerListSortType) {
            if (value !== null) SqueegeeLocalStorage.setItem('customerListSortOrderOwing', value);
            else SqueegeeLocalStorage.removeItem('customerListSortOrderOwing');
            this.reloadViewOptions();
        },
        set showActiveCustomers(value: boolean) {
            SqueegeeLocalStorage.setItem('showActiveCustomers', value.toString());
            this.reloadViewOptions();
        },
        set showInactiveCustomers(value: boolean) {
            SqueegeeLocalStorage.setItem('showInactiveCustomers', value.toString());
            this.reloadViewOptions();
        },

        set showProspectiveCustomers(value: boolean) {
            SqueegeeLocalStorage.setItem('showProspectiveCustomers', value.toString());
            this.reloadViewOptions();
        },

        set onlyShowOffSchedule(value: boolean) {
            SqueegeeLocalStorage.setItem('onlyShowOffSchedule', value.toString());
            this.reloadViewOptions();
        },

        set offScheduleToleranceLate(value: boolean) {
            SqueegeeLocalStorage.setItem('offScheduleToleranceLate', value.toString());
            this.reloadViewOptions();
        },

        set offScheduleToleranceEarly(value: boolean) {
            SqueegeeLocalStorage.setItem('offScheduleToleranceEarly', value.toString());
            this.reloadViewOptions();
        },

        set onlyOverdueUnpaidInvoices(value: boolean) {
            SqueegeeLocalStorage.setItem('onlyOverdueUnpaidInvoices', value.toString());
            this.reloadViewOptions();
        },

        set groupSchedule(value: boolean) {
            SqueegeeLocalStorage.setItem('groupSchedule', value.toString());
            this.reloadViewOptions();
        },
    };
    private static _instance: CommonApplicationState;

    @computedFrom('_instance')
    public static get instance() {
        return ApplicationState._instance;
    }

    public static clientVersionStatus: 'failed' | 'current' | 'outdated' | 'unsupported' | undefined;

    public static validateClientVersion(ping: IPingResponse) {
        if (ApplicationState.clientVersionStatus) return;

        try {
            let clientVersionStatus: 'failed' | 'current' | 'outdated' | 'unsupported' | undefined = ApplicationState.clientVersionStatus;
            let clientVersionMessage: TranslationKey | undefined;
            if (!ping) {
                // Couldn't get version information, go into offline mode.
                clientVersionStatus = 'failed';
                clientVersionMessage = 'notifications.version-check-failed';
            } else {
                const localVersion = data.version;
                const remoteVersionInfo = ping.versionInfo;
                const meetsMinimumVersion = isVersionGreaterOrEqual(localVersion, remoteVersionInfo.minimumSupportedVersion);
                const meetsRecommendedVersion = isVersionGreaterOrEqual(localVersion, remoteVersionInfo.version);
                if (meetsMinimumVersion === undefined || meetsRecommendedVersion === undefined) {
                    clientVersionStatus = 'failed';
                    clientVersionMessage = 'notifications.version-check-failed';
                    Logger.error(' Either local version or remote version info is garbage, going into offline mode', {
                        localVersion,
                        remoteVersionInfo,
                    });
                } else if (meetsMinimumVersion !== undefined && !meetsMinimumVersion) {
                    // The client is below the minimum supported version, going into offline mode
                    clientVersionStatus = 'unsupported';
                    clientVersionMessage = 'notifications.version-unsupported';
                } else if (meetsRecommendedVersion !== undefined && !meetsRecommendedVersion) {
                    // The client is below the current api version, alert the user to update.
                    clientVersionStatus = 'outdated';
                    clientVersionMessage = 'notifications.version-outdated';
                } else {
                    clientVersionStatus = 'current';
                }
            }

            if (clientVersionMessage && clientVersionStatus !== ApplicationState.clientVersionStatus && clientVersionStatus !== 'current') {
                const message = ApplicationState.localise(clientVersionMessage);
                if (
                    !GlobalFlags.isHttp &&
                    (GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice) &&
                    (clientVersionStatus === 'unsupported' || clientVersionStatus === 'outdated')
                ) {
                    new ActionableEvent(
                        ApplicationState.localise(message),
                        ApplicationState.localise('notifications.view-update'),
                        GlobalFlags.isAppleMobileDevice
                            ? 'https://itunes.apple.com/app/squeegee/id1223407652?ls=1&mt=8'
                            : 'https://play.google.com/store/apps/details?id=com.squeegee',
                        300000
                    );
                } else if (clientVersionStatus === 'unsupported') {
                    new NotifyUserMessage('update.app-checking', {}, 60000);
                } else {
                    new NotifyUserMessage('update.app-available', {});
                }
            }

            ApplicationState.clientVersionStatus = clientVersionStatus;
        } catch (error) {
            ApplicationState.clientVersionStatus = 'failed';
            Logger.error('Failed to get client version status', error);
        }
    }

    private static _accountOverrideData?: AuthorisedUser | null;

    public static getAccountOverride(): AuthorisedUser | null {
        if (this._accountOverrideData === undefined) {
            const accountOverrideData = SqueegeeLocalStorage.getItem('ACCOUNT_OVERRIDE');
            this._accountOverrideData = accountOverrideData ? (JSON.parse(accountOverrideData) as AuthorisedUser) : null;
            if (this._accountOverrideData) Api.refreshConnectionMetadata();
        }
        return this._accountOverrideData;
    }

    public static get dataEmail() {
        if (!RethinkDbAuthClient.session) return '';

        const accountOverrideData = this.getAccountOverride();

        return accountOverrideData ? accountOverrideData.dataEmail : RethinkDbAuthClient.session.email;
    }

    private static _userAuthorisation?: AuthorisedUser;

    public static get userAuthorisation(): AuthorisedUser {
        if (!this._userAuthorisation) {
            const name = ApplicationState.account.name || 'My Account';
            const email = (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || '';
            let userAuth = ApplicationState.getAccountOverride();

            if (!userAuth) {
                userAuth = AuthorisedUser.GetOwnerPermissions(email, name, email, name, ['Owner', 'Admin', 'Creator', 'Planner', 'Worker']);
            }

            this._userAuthorisation = userAuth;
        }
        return this._userAuthorisation;
    }

    public static async setAccountOverride(selectedAccount?: AuthorisedUser) {
        try {
            if (selectedAccount && selectedAccount.deactivated && ApplicationState.dataEmail !== RethinkDbAuthClient.session?.email) {
                await SqueegeeLocalStorage.removeItem('ACCOUNT_OVERRIDE');
                return Utilities.switchAccount(RethinkDbAuthClient.session?.email || '');
            }

            const accountOverride = ApplicationState.getAccountOverride();
            if (selectedAccount && accountOverride && JSON.stringify(selectedAccount) === JSON.stringify(accountOverride)) return;

            if (selectedAccount) {
                ApplicationState._accountOverrideData = selectedAccount;
                RethinkDbAuthClient.updateDefaultAccount(selectedAccount.dataEmail);
            } else {
                RethinkDbAuthClient.updateDefaultAccount(RethinkDbAuthClient.session?.email);
            }

            new LoaderEvent(true);

            // they are the same, return;
            if (!accountOverride && !selectedAccount) return;

            if (selectedAccount) await SqueegeeLocalStorage.setItem('ACCOUNT_OVERRIDE', JSON.stringify(selectedAccount));
            else await SqueegeeLocalStorage.removeItem('ACCOUNT_OVERRIDE');
            delete ApplicationState._accountOverrideData;

            delete ApplicationState._userAuthorisation;

            if (selectedAccount) await RethinkDbAuthClient.updateDefaultAccount(selectedAccount.dataEmail);
        } catch (error) {
            Logger.error('Failed during select account.', error);
        } finally {
            new LoaderEvent(false);
        }
    }

    private static get vibrateDisabled() {
        return SqueegeeLocalStorage.getItem('vibrateDisabled') === 'true';
    }

    private static set vibrateDisabled(value: boolean) {
        SqueegeeLocalStorage.setItem('vibrateDisabled', value ? 'true' : 'false');
    }
    public static vibrate(ms = 100) {
        if (ApplicationState.vibrateDisabled && navigator.vibrate) {
            navigator.vibrate(ms);
        }
    }

    private static _reauthenticateDialogRunning: Promise<boolean> | undefined;
    private static _reauthenticationPromise?: Promise<{ success: boolean; signout: boolean }>;
    public static lastAuthenticationError: any;
    private static _reauthenticateChecks = 0;
    public static async reauthenticate(): Promise<boolean> {
        if (!ApplicationState._reauthenticationPromise) {
            ApplicationState._reauthenticationPromise = new Promise<{ success: boolean; signout: boolean }>(async resolve => {
                try {
                    this._reauthenticateChecks++;
                    const timeout = setTimeout(() => {
                        this.lastAuthenticationError = 'Failed during reauthenticate due to timeout validating the session';
                        Logger.info('Failed during reauthenticate due to timeout validating the session');
                        return resolve({ success: false, signout: false });
                    }, 60000);

                    let hasValidSession = await RethinkDbAuthClient.validateSession();

                    clearTimeout(timeout);

                    if (!RethinkDbAuthClient.session || !RethinkDbAuthClient.session.email) {
                        this.lastAuthenticationError = 'No session or session email';
                        resolve({ success: false, signout: true });
                    } else {
                        if (!hasValidSession && RethinkDbAuthClient.session && RethinkDbAuthClient.session.email && Api.isConnected) {
                            if (!ApplicationState._reauthenticateDialogRunning) {
                                ApplicationState._reauthenticateDialogRunning = new Promise<boolean>(
                                    resolve => new ReauthenticateEvent(authenticated => resolve(authenticated))
                                );
                            }
                            hasValidSession = await ApplicationState._reauthenticateDialogRunning;
                        }

                        if (!hasValidSession) this.lastAuthenticationError = 'Not able to reauthenticate.';
                        resolve({ success: hasValidSession, signout: false });
                    }

                    delete ApplicationState._reauthenticateDialogRunning;
                    setTimeout(() => delete ApplicationState._reauthenticationPromise, 10000);
                } catch (error) {
                    this.lastAuthenticationError = { message: 'Failed during reauthenticate', error };
                    Logger.error('Failed during reauthenticate', error);
                    return resolve({ success: false, signout: false });
                }
            });
        }

        const result = await ApplicationState._reauthenticationPromise;

        if (result.signout) {
            await ApplicationState.signOut(false);
            return false;
        } else if (result.success) {
            this._reauthenticateChecks = 0;
        } else if (this._reauthenticateChecks > 25) {
            this._reauthenticateChecks = 0;
            Logger.error('Failed to reauthenticate 25 consecutive times.');
        }

        return result.success;
    }

    @computedFrom('ApplicationState.instance')
    public static get account() {
        return ApplicationState.instance && ApplicationState.instance.account;
    }

    private static _lastRoute?: string;
    public static get lastRoute() {
        if (!this._lastRoute) {
            let lastRoute = SqueegeeLocalStorage.getItem('lastRoute');
            if (!lastRoute) {
                lastRoute = 'customers';
                SqueegeeLocalStorage.setItem('lastRoute', lastRoute);
            }
            this._lastRoute = lastRoute || 'customers';
        }
        return this._lastRoute;
    }
    public static set lastRoute(lastRoute: string) {
        SqueegeeLocalStorage.setItem('lastRoute', lastRoute);
        this._lastRoute = lastRoute;
    }

    public static async setBusinessAddress(save = true) {
        const dialog = new AddressLookupDialog(ApplicationState.account.businessAddress);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationState.account.businessAddress = location;
            delete ApplicationState.weatherDataUpdatedDate;
            if (save) await ApplicationState.save();
            return true;
        } else {
            return false;
        }
    }

    public static async setAddress(save = true) {
        const dialog = new AddressLookupDialog(ApplicationState.account.address);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationState.account.address = location;
            if (!ApplicationState.account.businessAddress || !ApplicationState.account.businessAddress.addressDescription) {
                ApplicationState.account.businessAddress = location;
            }

            if (save) await ApplicationState.save();
        }
    }

    private static _position: GeolocationPosition | undefined;
    public static get position() {
        return ApplicationState._position;
    }

    private static positionUpdate(position: GeolocationPosition) {
        if (position && position.coords && position.coords.latitude && position.coords.longitude) {
            //only update and emit a message if position in tens of metres has changed
            if (
                !this._position ||
                !this._position.coords ||
                this._position.coords.latitude.toPrecision(4) !== position.coords.latitude.toPrecision(4) ||
                this._position.coords.longitude.toPrecision(4) !== position.coords.longitude.toPrecision(4)
            ) {
                this._position = position;
                UserService.updateCurrentUserLocation([position.coords.longitude, position.coords.latitude]);
                new PositionUpdatedEvent();
            }
        }
    }

    public static get weather(): WeatherDetailsDictionary | undefined {
        ApplicationState.weatherUpdate();
        return ApplicationState.weatherData;
    }

    private static weatherData: WeatherDetailsDictionary;
    private static weatherDataUpdatedDate: string | undefined;

    private static _weatherUpdateRunning = false;
    public static async weatherUpdate() {
        ApplicationState.initWeatherData();

        if (ApplicationState._weatherUpdateRunning) return;

        ApplicationState._weatherUpdateRunning = true;

        let needsSave = false;

        if (!ApplicationState.weatherDataUpdatedDate || !moment().isSame(ApplicationState.weatherDataUpdatedDate, 'day')) {
            const position = ApplicationState.position;
            let lat: number | undefined;
            let lng: number | undefined;

            if (
                ApplicationState.account.businessAddress &&
                ApplicationState.account.businessAddress.lngLat &&
                ApplicationState.account.businessAddress.lngLat.length === 2
            ) {
                lng = ApplicationState.account.businessAddress.lngLat[0];
                lat = ApplicationState.account.businessAddress.lngLat[1];
            } else if (position) {
                lat = position.coords.latitude;
                lng = position.coords.longitude;
            }

            if (lat && lng) {
                try {
                    const weatherData = await Api.getForecast(lng, lat);
                    if (weatherData) {
                        ApplicationState.weatherDataUpdatedDate = moment().format();
                        Object.assign(ApplicationState.weatherData, weatherData);
                        const twoWeeksAgo = moment().subtract(2, 'weeks').format('YYYY-MM-DD');
                        for (const date in ApplicationState.weatherData) {
                            if (date < twoWeeksAgo) delete ApplicationState.weatherData[date];
                        }
                        needsSave = true;
                    }
                } catch (error) {
                    Logger.error('Error during get the weather forecast during weatherUpdate', error);
                }
            }
            ApplicationState._weatherUpdateRunning = false;
        }

        if (needsSave) SqueegeeLocalStorage.setItem('WeatherData', JSON.stringify(ApplicationState.weatherData));
    }

    private static initWeatherData() {
        if (!ApplicationState.weatherData) {
            const localWeatherDataJson = SqueegeeLocalStorage.getItem('WeatherData');
            if (localWeatherDataJson) {
                ApplicationState.weatherData = JSON.parse(localWeatherDataJson);
            } else if ((<any>ApplicationState.instance).weather) {
                ApplicationState.weatherData = (<any>ApplicationState.instance).weather;
            } else {
                ApplicationState.weatherData = {};
            }
        }
    }

    public static get temperatureUnits() {
        return ApplicationState.instance.temperatureUnits;
    }
    public static set temperatureUnits(value: TemperatureUnitType) {
        ApplicationState.instance.temperatureUnits = value;
        ApplicationState.save();
    }
    public static fahrenheitToCelsius(tempInFahrenheit: number, decimalPlaces = 0) {
        return ((tempInFahrenheit - 32) * (5 / 9)).toFixed(decimalPlaces);
    }

    @computedFrom('instance.distanceUnits')
    public static get distanceUnits(): 'miles' | 'kilometres' {
        return ApplicationState.instance.distanceUnits === 'miles' ? 'miles' : 'kilometres';
    }
    public static set distanceUnits(value: DistanceUnitType) {
        ApplicationState.instance.distanceUnits = value;
        ApplicationState.save();
    }
    public static get distanceUnitsAbbreviated() {
        return ApplicationState.distanceUnits === 'miles' ? 'mi' : 'km';
    }

    public static updateProfileImagePath() {
        const customProfileImage = ApplicationState.hasUltimateOrAbove
            ? ApplicationState.getSetting<string>('global.profile-custom-image-url')
            : undefined;

        ApplicationState._profileImage = customProfileImage
            ? customProfileImage
            : ApplicationState.generateProfileImagePath(ApplicationState.instance.profileImage || 1);
    }

    public static get capacityMeasurementType() {
        return ApplicationState.instance.capacityMeasurementType;
    }
    public static set capacityMeasurementType(type: CapacityMeasurementType) {
        ApplicationState.instance.capacityMeasurementType = type;
        ApplicationState.save();
    }

    public static get workloadCapacityMeasurementType() {
        return ApplicationState.instance.workloadCapacityMeasurementType || CapacityMeasurementType.NUMBER_OF_JOBS;
    }
    public static set workloadCapacityMeasurementType(type: CapacityMeasurementType) {
        ApplicationState.instance.workloadCapacityMeasurementType = type;
        ApplicationState.save();
    }

    public static get workloadCapacityAmount() {
        return ApplicationState.instance.workloadCapacityAmount || 30;
    }
    public static set workloadCapacityAmount(amount: number) {
        ApplicationState.instance.workloadCapacityAmount = amount;
        ApplicationState.save();
    }

    public static set workloadCapacityMeasurementBenchmark(benchmark: CapacityBenchmarkType) {
        ApplicationState.instance.workloadCapacityMeasurementBenchmark = benchmark;
        ApplicationState.save();
    }

    public static get capacityMeasurementBenchmark() {
        return ApplicationState.instance.capacityMeasurementBenchmark;
    }

    public static set capacityMeasurementBenchmark(benchmark: CapacityBenchmarkType) {
        ApplicationState.instance.capacityMeasurementBenchmark = benchmark;
        ApplicationState.save();
    }

    public static readonly totalProfileImages = 30;

    private static _profileImage?: string;
    @computedFrom('_profileImage')
    public static get profileImage() {
        if (ApplicationState._profileImage === undefined) ApplicationState.updateProfileImagePath();
        return ApplicationState._profileImage;
    }

    public static generateProfileImagePath(id: number) {
        return './images/profile/' + id + '.jpg';
    }

    public static setProfileImage(value: number) {
        ApplicationState.instance.profileImage = value;
        ApplicationState.updateProfileImagePath();
        ApplicationState.save();
    }

    public static async setLanguage(languageCode: string) {
        const localeCode = ApplicationState.instance.account.language;
        const countryCode = localeCode ? localeCode.substring(localeCode.length - 2) : 'GB';

        ApplicationState.setCountryAndLanguageCode(`${languageCode}-${countryCode}`);
    }

    public static async setCountry(countryCode: string) {
        const localeCode = ApplicationState.instance.account.language;
        const languageCode = localeCode ? localeCode.substring(0, 2) : 'en';

        ApplicationState.setCountryAndLanguageCode(`${languageCode}-${countryCode}`);
    }

    public static async setCountryAndLanguageCode(localeCode: string) {
        if (localeCode.length !== 5) throw 'Invalid country code';
        ApplicationState.instance.account.language = localeCode;
        await ApplicationState.refreshLocale();
        ApplicationState.instance.account.currency = ApplicationState.countryConfig('currencyCode') as keyof Currency;
        await ApplicationState.save();
    }

    public static async refreshLocale() {
        ApplicationState.localisationCache = {} as Record<TranslationKey, TranslationKey>;
        let localeCode = ApplicationState.instance.account.language;
        i18next.changeLanguage(localeCode);
        let updated = false;
        if (!localeCode) {
            const locale = ApplicationState.predictLocale();
            localeCode = `${locale.languageCode}-${locale.countryCode}`;
            ApplicationState.instance.account.language = localeCode;
            updated = true;
        }
        if (ApplicationState.instance.account.currency === undefined) {
            ApplicationState.instance.account.currency = ApplicationState.countryConfig('currencyCode') as keyof Currency;
            updated = true;
        }

        if (updated) ApplicationState.save();

        moment.locale(localeCode);
        await ApplicationEnvironment.i18nInstance.setLocale(localeCode);
        ApplicationEnvironment.router && ApplicationEnvironment.router.updateTitle();
    }

    public static predictLocale() {
        let languageCode = 'en';
        let countryCode = 'GB';

        const detectedLocale: string = window && window.navigator && (window.navigator.language || (<any>window.navigator).userLanguage);
        if (detectedLocale && detectedLocale.length >= 2) {
            const languages = LanguagesAndCountries.languages;
            const detectedLanguageCode = detectedLocale.substring(0, 2);
            if (languages[detectedLanguageCode]) languageCode = detectedLanguageCode;
            if (detectedLocale.length === 5) {
                const countries = LanguagesAndCountries.countries;
                const detectedCountryCode = detectedLocale.substring(3, 5);
                if (countries[detectedCountryCode]) countryCode = detectedCountryCode;
            }
        }

        return { languageCode, countryCode };
    }

    private static localisationCache = {} as Record<string, TranslationKey>;
    public static localise(key: TranslationKey, options?: { [key: string]: string }): TranslationKey {
        try {
            if (!key) return '' as TranslationKey;

            const cacheKey = key + (options ? '__' + JSON.stringify(options) : '');

            if (ApplicationState.localisationCache[cacheKey] !== undefined) return ApplicationState.localisationCache[cacheKey];

            if (key === <TranslationKey>'config.currencyCode') {
                ApplicationState.localisationCache[cacheKey] = <TranslationKey>ApplicationState.currencyCode();
                return ApplicationState.localisationCache[cacheKey];
            }

            if (key === <TranslationKey>'config.currencySymbol') {
                ApplicationState.localisationCache[cacheKey] = <TranslationKey>ApplicationState.currencySymbol();
                return ApplicationState.localisationCache[cacheKey];
            }

            if (key.startsWith('config.')) {
                ApplicationState.localisationCache[cacheKey] = ApplicationState.countryConfig(<keyof ICountryConfig>key.substring(7));
                return ApplicationState.localisationCache[cacheKey];
            }

            if (typeof key !== 'string') {
                Logger.error('Invalid localisation key, must be a string:', key);
                ApplicationState.localisationCache[cacheKey] = ApplicationEnvironment.i18nDebugEnabled
                    ? <TranslationKey>`?🔴?`
                    : <TranslationKey>'?';
                return ApplicationState.localisationCache[cacheKey];
            }

            if (key.indexOf('=') === -1 && /[^\d\w.-]/.test(key)) return key;

            let translate: undefined | ((key: TranslationKey, options?: Record<string, string>) => TranslationKey);
            if (ApplicationEnvironment.i18nInstance && (<any>ApplicationEnvironment.i18nInstance).realTr) {
                translate = (key: string, options?: any) =>
                    (<any>ApplicationEnvironment.i18nInstance).realTr(key, options) as TranslationKey;
            }

            if (!translate) {
                Logger.info(`Attempted to translate "${key}" before the translation engine is initialised.`);
                ApplicationState.localisationCache[cacheKey] = key;
                return ApplicationState.localisationCache[cacheKey];
            }

            options = (options && Utilities.copyObject(options)) || undefined;

            const debugging = ApplicationEnvironment.i18nDebugEnabled && !key.startsWith('config.');
            let translation: TranslationKey;

            if (!Localisation.isLocalised(key)) {
                if (debugging) translation = `${key} 🔴` as TranslationKey;
                else translation = key;
            } else {
                translation = translate(key, options);
                if (translation === key) {
                    if (debugging) translation = `${Localisation.getDefault(key, options)} 🌐` as TranslationKey;
                    else translation = Localisation.getDefault(key, options);
                } else {
                    if (debugging) translation = `${translation} 🌎` as TranslationKey;
                }
            }

            translation = translation.replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<') as TranslationKey;

            ApplicationState.localisationCache[cacheKey] = translation;
            ApplicationState.localisationCache[translation] = translation;

            return translation;
        } catch (error) {
            Logger.error('Translation failure', { key, options });
            return '' as TranslationKey;
        }
    }

    public static countryConfig(configItemKey: keyof ICountryConfig, countryCode?: string) {
        countryCode =
            countryCode ||
            (ApplicationState.account.language.length === 5 &&
                ApplicationState.account.language.substring(ApplicationState.account.language.length - 2)) ||
            undefined;
        if (!countryCode || countryCode === 'undefined') {
            const localeData = ApplicationState.predictLocale();
            countryCode = localeData.countryCode;
            ApplicationState.setCountryAndLanguageCode(`${localeData.languageCode}-${localeData.countryCode}`);
        }
        const config: ICountryConfig = countryCode && (<any>Countries)[countryCode];
        if (!config) {
            Logger.error(
                `Error during get country config from country code "${countryCode}" generated from locale "${ApplicationState.account.language}"`
            );
            return <TranslationKey>'?';
        } else {
            return <TranslationKey>config[configItemKey];
        }
    }
    public static currencySymbol() {
        const currency = Currency.get(<keyof Currency>ApplicationState.account.currency);
        return (currency && currency.symbol) || '';
    }

    public static currencyCode() {
        return ApplicationState.account.currency || '';
    }

    public static async updateSubscription(toSubscription?: IProductPlanSubscription): Promise<IProductPlanSubscription> {
        if (toSubscription) {
            ApplicationState.subscription = toSubscription;
            ApplicationState.refreshNavigation();

            return toSubscription;
        }

        const currentSubscription = ApplicationState.subscription;
        toSubscription = await Api.getSubscription();

        if (!toSubscription || (currentSubscription && JSON.stringify(toSubscription) === JSON.stringify(currentSubscription))) {
            return currentSubscription;
        } else {
            ApplicationState.subscription = toSubscription;
            ApplicationState.refreshNavigation();
        }

        return toSubscription;
    }

    public static preInit() {
        ApplicationState.stateFlags = new ApplicationStateFlags();
    }

    public static localiseRoleName(role: UserRole) {
        return ApplicationState.localise(`role.${role}`);
    }

    private static _messagingTemplates: AccountMessagingTemplates | undefined;
    public static get messagingTemplates(): AccountMessagingTemplates {
        if (ApplicationState._messagingTemplates === undefined) {
            const cleanTemplates = copyObject(ApplicationState.account.messagingTemplates || ({} as AccountMessagingTemplates));
            for (const key in cleanTemplates) {
                const realKey = key as keyof AccountMessagingTemplates;
                const value = cleanTemplates[realKey];
                if (typeof value === 'string' && value.trim() === '') delete cleanTemplates[realKey];
            }
            ApplicationState._messagingTemplates = new AccountMessagingTemplates();

            ApplicationState._messagingTemplates.smsPriceChange = MessageTemplates.instance.smsPriceChange.default;
            ApplicationState._messagingTemplates.emailPriceChange = MessageTemplates.instance.emailPriceChange.default;
            ApplicationState._messagingTemplates.smsMessage = MessageTemplates.instance.smsMessage.default;
            ApplicationState._messagingTemplates.emailMessage = MessageTemplates.instance.emailMessage.default;
            ApplicationState._messagingTemplates.smsAppointmentReminder = MessageTemplates.instance.smsAppointmentReminder.default;
            ApplicationState._messagingTemplates.emailAppointmentReminder = MessageTemplates.instance.emailAppointmentReminder.default;
            ApplicationState._messagingTemplates.smsPaymentRequest = MessageTemplates.instance.smsPaymentRequest.default;
            ApplicationState._messagingTemplates.emailPaymentRequest = MessageTemplates.instance.emailPaymentRequest.default;
            ApplicationState._messagingTemplates.smsInvoice = MessageTemplates.instance.smsInvoice.default;
            ApplicationState._messagingTemplates.emailInvoice = MessageTemplates.instance.emailInvoice.default;
            ApplicationState._messagingTemplates.smsAutomaticPaymentInvite = MessageTemplates.instance.smsAutomaticPaymentInvite.default;
            ApplicationState._messagingTemplates.emailAutomaticPaymentInvite =
                MessageTemplates.instance.emailAutomaticPaymentInvite.default;

            Object.assign(ApplicationState._messagingTemplates, cleanTemplates) as AccountMessagingTemplates;
            ApplicationState.account.messagingTemplates = ApplicationState._messagingTemplates;
        }
        return ApplicationState._messagingTemplates;
    }

    public static initPosition() {
        ApplicationState._positionInitialised = new Promise<void>(resolve => {
            try {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.positionNotAvailable = false;
                        ApplicationState.positionUpdate(position);
                        return resolve();
                    },
                    error => {
                        this.positionNotAvailable = true;

                        Logger.warn('Position not unavailable', <any>error);
                        return resolve();
                    }
                );
            } catch (error) {
                Logger.warn('Position not unavailable', <any>error);
                this.positionNotAvailable = true;
                return resolve();
            }
        });

        try {
            navigator.geolocation.watchPosition(
                position => {
                    this.positionNotAvailable = false;
                    ApplicationState.positionUpdate(position);
                },
                error => {
                    this.positionNotAvailable = true;
                    Logger.warn('Position not unavailable', <any>error);
                }
            );
        } catch (error) {
            this.positionNotAvailable = true;
            Logger.warn('Position not unavailable', <any>error);
        }
    }

    public static async signIn(password: string): Promise<boolean> {
        const signInResult = await RethinkDbAuthClient.signin(ApplicationState.account.email, password);
        if (!signInResult.signedIn) throw 'Sign in failed';

        return true;
    }

    public static async signOut(showConfirm = true) {
        try {
            const impersonating = ApplicationState.isSupportUser;
            // if were are impersonating always clear local data dont prompt
            let clearLocalData = impersonating || false;
            if (showConfirm && !impersonating) {
                const clearLocalDataPrompt = prompt(
                    'prompts.sign-out-clear-data-confirm',
                    'prompts.sign-out-clear-data-confirm-text',
                    {
                        altLabel: 'general.sign-out',
                        okLabel: 'prompts.sign-out-and-clear',
                    },
                    undefined
                );

                clearLocalData = await clearLocalDataPrompt.show();
                if (clearLocalDataPrompt.cancelled) return;
            }

            new LoaderEvent(true, true, 'general.signing-out');

            if (showConfirm && ((RethinkDbAuthClient.session && RethinkDbAuthClient.session.freeDevice) || GlobalFlags.isHttp)) {
                new LoaderEvent(true, true, 'general.signing-out');
                await Data.removeLocalData(clearLocalData);
            } else {
                new LoaderEvent(true, true, 'general.signing-out');
            }
            await RethinkDbAuthClient.signOut(RethinkDbAuthClient.session);

            document.location && document.location.reload();
        } catch (error) {
            Logger.error('Error during sign out cleanly', error);
        }
    }

    public static async save(suppress = false) {
        try {
            await ApplicationState.refreshLocale();
            const notify: DataChangeNotificationType = suppress ? 'lazy' : 'immediate';
            await Data.put(ApplicationState.instance, true, notify);
            ApplicationState._messagingTemplates = undefined;
        } catch (error) {
            Logger.error('Error during save the application state.', error);
        }
    }

    public static decrementShowDevModeCounter() {
        if (ApplicationState.stateFlags.showDevModeCounter > 0) {
            ApplicationState.stateFlags.showDevModeCounter--;
            if (ApplicationState.stateFlags.showDevModeCounter === 3) {
                ApplicationState.stateFlags.enablingDevMode = true;
            } else if (ApplicationState.stateFlags.showDevModeCounter === 0) {
                ApplicationState.stateFlags.enablingDevMode = false;
                ApplicationState.stateFlags.devMode = !ApplicationState.stateFlags.devMode;
                ApplicationState.stateFlags.showDevModeCounter = 20;
                new PermissionsUpdateEvent();
            }
        }
    }

    public static openDevModeDialog() {
        const dialog = new DeveloperLaunchOptionsDialog();
        dialog.show(DialogAnimation.SLIDE);
        return dialog;
    }

    private static _apiEndpointOverride: string | undefined;

    public static get apiEndpointOverride(): string | undefined {
        try {
            if (this._apiEndpointOverride === undefined) {
                const apiEndpointOverride = SqueegeeLocalStorage.getItem('apiEndpointOverride') || '';
                this._apiEndpointOverride = apiEndpointOverride;
            }
            return <string>ApplicationState._apiEndpointOverride;
        } catch (error) {
            Logger.error('Error during get api endpoint override', error);
            return undefined;
        }
    }

    public static set apiEndpointOverride(endpoint: string | undefined) {
        ApplicationState._apiEndpointOverride = endpoint;
        if (endpoint) SqueegeeLocalStorage.setItem('apiEndpointOverride', endpoint);
        else {
            const apiEndpointOverride = SqueegeeLocalStorage.getItem('apiEndpointOverride');
            if (apiEndpointOverride) SqueegeeLocalStorage.removeItem('apiEndpointOverride');
        }
    }

    public static get dd() {
        return ApplicationState.countryConfig('directDebitName');
    }

    public static get multiUserEnabled(): boolean {
        return ApplicationState.subscription.users !== undefined && ApplicationState.subscription.users > 1;
    }

    public static get hasAdvancedOrAbove(): boolean {
        return ApplicationState.hasMinimumSubscription(ProductLevel.Advanced);
    }

    public static get hasUltimateOrAbove(): boolean {
        return ApplicationState.hasMinimumSubscription(ProductLevel.Ultimate);
    }

    public static get automaticPaymentsInviteMessage(): string {
        const { name, businessName } = ApplicationState.account;
        /* TODO REMOVE ONCE CUSTOM TEMPLATES ARE ALLOWED
            Legacy code  allowed custom invitation message.
            However some accounts have a broken version saved that says 'has invited you' instead of '[businessName] has invited you'
            So if they have a broken version return the new non saved version instead
        */
        if (
            typeof (ApplicationState.instance as any).automaticPaymentsInviteMessage === 'string' &&
            (ApplicationState.instance as any).automaticPaymentsInviteMessage.trim().indexOf('has')
        ) {
            return (ApplicationState.instance as any).automaticPaymentsInviteMessage;
        }
        return ApplicationState.localise('account.payments-invite-message', {
            name: businessName || name,
            dd: ApplicationState.dd,
        });
    }

    public static hasAutomaticPaymentMethod(): boolean {
        return ApplicationState.hasCardProvider() || ApplicationState.hasDirectDebitProvider();
    }

    public static hasCardProvider(): boolean {
        const oauthConnections = ApplicationState.getSetting<any>('global.oauth-connections');

        if (!oauthConnections?.stripe) {
            // Also check legacy
            if (!ApplicationState.account.stripePublishableKey) return false;
        }
        return true;
    }

    public static hasDirectDebitProvider(): boolean {
        const oauthConnections = ApplicationState.getSetting<any>('global.oauth-connections');

        if (!oauthConnections?.gocardless) {
            // Also check legacy
            if (!ApplicationState.account.goCardlessPublishableKey) return false;
        }
        return true;
    }

    public static hasValidbusinessAddress() {
        return ApplicationState.account?.businessAddress?.lngLat?.length;
    }

    private static _hideDistanceMarkers: boolean;
    public static get hideDistanceMarkers() {
        if (ApplicationState._hideDistanceMarkers === undefined) {
            ApplicationState._hideDistanceMarkers = SqueegeeLocalStorage.getItem('hideDistanceMarkers') === 'true';
        }
        return ApplicationState._hideDistanceMarkers;
    }

    public static set hideDistanceMarkers(enabled: boolean) {
        ApplicationState._hideDistanceMarkers = enabled;
        SqueegeeLocalStorage.setItem('hideDistanceMarkers', enabled.toString());
    }

    public static allowedRolesToViewJobAttachments: Array<UserRole> = ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'];

    public static activeUsersCount() {
        return Data.count('accountuser');
    }

    public static setDefaultSettings() {
        try {
            QuoteService.getQuoteSettings();
        } catch (error) {
            Logger.error('Unable to set default settings', error);
        }
    }
    public static features = new Features();

    public static unreadNotificationsCount() {
        const notifications = [
            ...Data.all<Notification>('notifications', { unread: true, type: 'EmailReply' }),
            ...Data.all<Notification>('notifications', { unread: true, type: 'SMSReply' }),
            ...Data.all<Notification>('notifications', { unread: true, type: 'ChatReply' }),
            ...Data.all<Notification>('notifications', { unread: true, type: 'Feedback' }),
        ];

        // If group by customer is enabled, we only want to show the latest notification for each customer

        if (SqueegeeLocalStorage.getItem('groupByCustomer') === 'true') {
            const customerNotifications = new Set<string | undefined>();

            for (const notification of notifications) {
                customerNotifications.add(notification.customerId);
            }

            return customerNotifications.size;
        }

        return notifications.length;
    }

    public static get landingPageRoute() {
        return RouteUtil.getRoutes().find(route => route.route === ApplicationState.landingPage);
    }

    public static get landingPage() {
        return SqueegeeLocalStorage.getItem('landingPage');
    }

    public static set landingPage(page: string | null) {
        if (!page) {
            SqueegeeLocalStorage.removeItem('landingPage');
        } else SqueegeeLocalStorage.setItem('landingPage', page);
    }

    public static get isSupportUser() {
        return !!RethinkDbAuthClient.session?.freeDevice;
    }

    public static async getDirectoryEntryDetails() {
        try {
            const response = await Api.get<IDirectoryEntryDetails>(Api.apiEndpoint, `/api/directory/secure/${ApplicationState.dataEmail}`);
            return response?.data;
        } catch (error) {
            Logger.error('Error during getDirectoryEntry', { error });
        }
    }
    private static async handleCrossBrowserStorageEvent(event: StorageEvent) {
        switch (event.key) {
            case 'url-logout-event': {
                await wait(250);
                localStorage.removeItem('url-logout-event');
                window.location.reload();
                break;
            }
            default:
                break;
        }
    }

    private static initCrossBrowserEvents() {
        try {
            window.removeEventListener('storage', e => this.handleCrossBrowserStorageEvent(e));
            window.addEventListener('storage', e => this.handleCrossBrowserStorageEvent(e));
        } catch (error) {
            Logger.error('Error during initCrossBrowserEvents', { error });
        }
    }
    // currently 2 way emails CAN NOT be scheduled
    public static get canScheduleEmails() {
        return ApplicationState.hasUltimateOrAbove && ApplicationState.account.emailAPI !== 'email-engine';
    }

    public static get canScheduleSms() {
        return ApplicationState.hasUltimateOrAbove;
    }
}
